<template>
    <div class="animation">
 <!-- List of bookings -->
    <b-card>
        <b-form>
            <b-row>
                <b-col sm="12">
                    <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
                        
                    </v-client-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <b-form-group >
                        <b-form-file required v-on:change="uploadSheet($event.target)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ref="fileinput" id="fileInput" :plain="true"></b-form-file>
                    </b-form-group>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col sm="2" offset="5">
                    <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
    <!-- End List -->
</div>
</template>
<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
import CinemaService from '@/services/CinemaService'
Vue.use(ClientTable)
export default {
    name: 'SendConfirmation',
    components: {
        ClientTable,
        Event
  },
  data() {
      return {
        confirmation: {
            table: '',
            sheet: ''
        },
        data: [],
        columns: ['CinemaName', 'CinemaID', 'MovieTitle', 'NumberOfSeats', 'Date', 'Time'],

        options: {
          headings: {
            CinemaName: 'Cinema Name',
            CinemaID: 'CinemaID',
            MovieTitle: 'Movie',
            NumberOfSeats: 'Seats',
            Date: 'Date',
            Time: 'Time'
          },
          sortable: ['CinemaName', 'CinemaID', 'Date', 'Time'],
          filterable: ['CinemaName', 'CinemaID', 'Date', 'Time'],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          pagination: {
            chunk: 5,
            edge: false,
            nav: 'scroll'
          }
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default' 
    }
  },
  mounted() {
      this.getData()
  },
  methods: {
      async getData() {
        let res = await CinemaService.getBookingList()
        this.data = res.data
      }
  }
}
</script>
<style>

</style>
